<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="issue"
            rules="required"
          >
            <b-form-group
              label="issue"
              label-for="issue"
            >
              <b-form-input
                id="issue"
                v-model="taskForm.issue"

                :state="getValidationState(validationContext)"
                trim
                placeholder="issue"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="priority"
            rules="required"
          >
            <b-form-group
              label="priority"
              label-for="priority"
            >
              <v-select
                id="priority"
                v-model="taskForm.priority"
                :options="priorityOptions"
                :state="getValidationState(validationContext)"
                trim
                placeholder="choose priority"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="due date"
            rules="required"
          >
            <b-form-group
              label="Due Date"
              label-for="Due Date"
            >
              <b-form-input
                id="due_date"
                v-model="taskForm.due_date"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="due date"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="required action"
            rules="required"
          >
            <b-form-group
              label="required action"
              label-for="required action"
            >
              <b-form-input
                id="required_action"
                v-model="taskForm.required_action"
                :state="getValidationState(validationContext)"
                trim
                placeholder="required action"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Request Number"
            label-for="Request Number"
          >
            <b-form-input
              id="request_id"
              v-model="taskForm.request_id"
              trim
              placeholder="Request Number"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="assigned to"
            rules="required"
          >
            <b-form-group
              label="assigned to"
              label-for="assigned to"
            >
              <v-select
                id="assigned_to"
                v-model="taskForm.assigned_to"
                :options="AssignedToOptions"
                :state="getValidationState(validationContext)"
                trim
                placeholder="choose priority"
                multiple
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="12">

          <b-form-group
            label="description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="taskForm.description"
              type="date"
              trim
              placeholder="description"
            />
          </b-form-group>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addTask()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addTask()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'

import decryptData from '@/store/auth/decryptData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    'v-select': VSelect,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      priorityOptions: ['low', 'medium', 'high'],
      AssignedToOptions: [],

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const taskForm = ref({})
    return {
      getValidationState,
      taskForm,
    }
  },
  mounted() {
    this.getAssignedToList()
    this.showTask()
  },
  methods: {

    getAssignedToList() {
      axios.get('https://gulftic-system.fci.group/api/task/assign-list').then(res => {
        const allAssignedTo = decryptData(res.data.payload)?.data
        this.AssignedToOptions = allAssignedTo ? allAssignedTo.map(item => ({
          label: `${item.name} - ${item.email}`,
          value: {
            id: item.id,
            type: item.type,
          },
        })) : []
      })
    },
    showTask() {
      this.loader = true
      if (this.$route.params.id) {
        axios.get(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`).then(res => {
          const getAllData = decryptData(res.data.payload)
          this.taskForm = getAllData.data
          this.taskForm.assigned_to = getAllData.data.assigned.map(item => ({
            label: `${item.user.name} - ${item.user.email}`,
            value: {
              id: item.user_id,
              type: item.user_type,
            },
          }))
        })
      } else {
        this.loader = false
        return false
      }
      this.loader = false
      return true
    },
    // showTask() {
    //   this.loader = true
    //   if (this.$route.params.id) {
    //     axios.get(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`).then(res => {
    //       const getAllData = decryptData(res.data.payload)
    //       this.taskForm = getAllData.data

    //       console.log(this.AssignedToOptions)

    //       // Safely map assigned users for multi-select
    //       this.taskForm.assigned_to = getAllData.data.assigned?.map(item => {
    //         // Find matching assigned option by user_id and user_type
    //         const assignedOption = this.AssignedToOptions.find(option => option.value.id === item.user_id && option.value.type === item.user_type)
    //         return assignedOption ? { label: assignedOption.label, value: assignedOption.value } : null
    //       }).filter(item => item !== null) || [] // Ensure no null values in the array

    //       console.log(this.taskForm.assigned_to) // Check if this is an array of objects
    //     })
    //   } else {
    //     this.loader = false
    //     return false
    //   }
    //   this.loader = false
    //   return true
    // },

    addTask() {
      if (this.$route.params.id) {
        this.loader = true
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        const payloadData = {}

        Object.entries(this.taskForm).forEach(([key, value]) => {
          if (key === 'assigned_to' && Array.isArray(value)) {
            const assignedValues = value.map(item => item.value)
            payloadData[key] = JSON.parse(JSON.stringify(assignedValues))
          } else {
            payloadData[key] = value
          }
        })

        axios.put(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`, payloadData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'Task Management Created' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true

        const payloadData = {}

        Object.entries(this.taskForm).forEach(([key, value]) => {
          if (key === 'assigned_to' && Array.isArray(value)) {
            const assignedValues = value.map(item => item.value)
            payloadData[key] = JSON.parse(JSON.stringify(assignedValues))
          } else {
            payloadData[key] = value
          }
        })

        axios.post('https://gulftic-system.fci.group/api/task', payloadData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'Task Management Created' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
